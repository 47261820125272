<template lang='pug'>
.container-fluid 
  .row(v-for="(row, key) in grid")
    .col-6(v-for="(value, k) in row")
      .flex(:value="value")
        dt(class="col tw-my-2 tw-px-2 tw-text-right") {{value.label}}
        dd.col.tw-my-2.tw-px-2
          active-value(v-if='value' :value='value' :editing='editing')

</template>

<script>

import ActiveValue from './active-value.vue';

export default {
  props: ["data-grid", "dataEditing"],
  components:{'active-value': ActiveValue},
  data: function () {
    return {
      editing: this.dataEditing,
      grid: this.dataGrid,
    };
  },
  watch: {
    dataEditing: function (newVal, oldVal) {
      this.editing = newVal;
    },
  },
};
</script>